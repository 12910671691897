.tab {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #56646f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  gap: 20px;
  margin-top: 16px;
  margin-bottom: 54px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
    gap: 12px;
    margin-bottom: 34px;
  }
  & .active {
    text-decoration: underline;
  }
}
