@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);

    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.servicesContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 50px 0 55px;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(#landing-hero) {
  min-height: 650px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > div {
    & > div {
      max-width: 1520px;
    }
  }
}

.brandContent {
  padding: 0px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }

  & > div {
    & > div {
      & a {
        display: block;
        border-radius: 50px;
      }
    }
  }
}

.collectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }

  & > div {
    & > div {
      & > div {
        &:last-child {
          /* background-color: red; */
        }
      }
    }
  }
}
