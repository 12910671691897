.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

:global(#landing-hero) {
  & > div {
    & img {
      @media (max-width: 576px) {
        object-position: 78% 19%;
      }
    }
  }

  & .text {
    max-width: 526px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  & h1 {
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
    /* 116% */
    letter-spacing: 1px;
    font-family: Literata;
    @media (max-width: 767px) {
      color: #5f635c;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;

      /* color: #fff; */
    }
  }

  & p {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    /* 138.889% */
    letter-spacing: 1px;
    max-width: 450px;

    @media (max-width: 767px) {
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1px;
      text-align: center;
      color: #5f635c;
      max-width: 100%;
    }
  }

  & ul {
    margin: 50px 0 0;
    padding: 0;
    @media (max-width: 767px) {
      margin: 18px 0 0;
    }
    & li {
      list-style: none;
      display: flex;
      gap: 15px;

      @media (max-width: 767px) {
        /* flex-direction: column; */
        justify-content: center;
      }

      & a {
        border-radius: var(--marketplaceButtonBorderRadius);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        /* 277.778% */
        letter-spacing: 1px;
        display: flex;
        width: 190.122px;
        height: 48.318px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        background-color: #fff;
        /* border-radius: 50px; */
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        @media (max-width: 767px) {
          width: 148px;
          height: 37px;
          font-size: 14px;
          font-weight: 600;
          line-height: 50px;
          letter-spacing: 1px;
          text-align: center;
          color: #5f635c;
        }
      }
    }
  }
}

:global(#brands) {
  & > div {
    background: #fff;
    @media (min-width: 768px) {
      padding: 22px 0;
    }
    & > div {
      display: flex;
      max-width: 1650px;
      overflow-x: auto;
      padding: 20px 0;
      @media (max-width: 1060px) {
        max-width: calc(100vw - 40px);
        gap: 18px;
      }
      @media (min-width: 768px) {
        padding: 20px 32px;
      }
    }
  }

  & .root {
    border-radius: var(--marketplaceButtonBorderRadius);
    border: 1px solid #233541;
    background: #fff;
    flex: 1;

    & h3 {
      color: #3c4144;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 181.818% */
      white-space: nowrap;
      padding: 0 22px;
    }

    & a {
      border: none;
      background-color: #fff;
      color: #3c4144;
      text-align: center;
      justify-content: center;
      margin-top: 0px;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 181.818% */
      white-space: nowrap;
      padding: 0 22px;
    }
  }
}

:global(#collections) {
  background: var(--Cream, #f8f4f3);

  & > div {
    & > div {
      padding-top: 54px;
    }
  }

  & img {
    border-radius: 0;
  }

  & .root {
    position: relative;
  }

  & .text {
    position: relative;

    & h3 {
      color: var(--marketplaceTextColor);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 222.222% */
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      width: max-content;
      padding: 0 80px;
      margin: -40px auto 0;
      z-index: 3;
      position: relative;
      background-color: #fff;
    }

    & a {
      border-radius: var(--marketplaceButtonBorderRadius);

      color: var(--marketplaceTextColor);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 222.222% */
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      width: max-content;
      padding: 0 80px;
      /* margin: -40px auto 0; */
      z-index: 3;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      margin-top: -20px;
      background-color: #fff;
    }
  }

  & header {
    & h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      /* 250% */
      letter-spacing: 1px;
      margin: 0;
      padding: 0;
    }

    & p {
      font-size: 33px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      /* 121.212% */
      margin: 0;
      padding: 0;
    }
  }
}

:global(#love-notes) {
  & > div {
    background-color: #fff;
  }

  & .media {
    max-width: 163px;
  }

  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    & h2 {
      font-size: 33px;
      font-style: normal;
      font-weight: 400;
      line-height: 58px;
      /* 175.758% */
      letter-spacing: 1px;
      margin: 0;
      padding: 0;

      @media (max-width: 576px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    & a {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      /* 277.778% */
      text-decoration-line: underline;
      background-color: #fff;
      box-shadow: none;
      display: flex;
      align-items: center;
      gap: 4px;

      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 20px;
      }

      &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 1.99707C6 1.99707 4 15.9971 3 21.9971H4.998C5.664 18.6641 7.331 16.8301 10 16.4971C14 15.9971 17 12.4971 18 9.49707L16.5 8.49707L17.5 7.49707C18.5 6.49707 19.504 4.99707 21 1.99707Z' fill='%23223440'/%3E%3C/svg%3E%0A");
      }
    }
  }

  & .root {
    display: flex;
    gap: 23px;

    & .text {
      margin: 0;
    }

    & h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 471.429% */
    }

    & p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      /* 137.5% */
      margin: 0;
      padding: 0;
    }
  }
}

:global(#collection-banner) {
  background: var(--Cream, #f8f4f3);

  & .root {
    display: flex;
    flex-direction: column-reverse;
    gap: 34px;

    & .text {
      & p {
        max-width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;

        & a {
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          /* 277.778% */
          letter-spacing: 1px;
          text-decoration-line: underline;
          text-align: center;
        }
      }

      & h3 {
        max-width: 100%;
        font-size: 33px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        /* 121.212% */
        text-align: center;
        margin: 0;
        padding: 0;

        @media (max-width: 576px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }
  }
}

:global(#products) {
  background: var(--Cream, #f8f4f3);

  & > div {
    padding-top: 0;
  }

  & header {
    display: block;

    & h2 {
      font-size: 33px;
      font-style: normal;
      font-weight: 400;
      line-height: 58px;
      /* 175.758% */
      letter-spacing: 1px;
      max-width: 100%;
      text-align: left;

      @media (max-width: 576px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}

:global(#products-slider) {
  & > div {
    border-radius: 4px;
    background: #fff;
    overflow: hidden;

    & .media {
      width: 100%;
      max-height: 284px;
    }

    & img {
      width: 100%;
      max-height: 284px;
    }

    & .text {
      margin: 0;
      padding: 24px;

      @media (max-width: 576px) {
        padding: 16px;
      }

      & h3 {
        color: var(--marketplaceTextColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        /* 362.5% */
        letter-spacing: 1px;
        margin: 0;
        padding: 0;

        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 28px;
        }
      }

      & p {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 290% */
        margin: 0;
        padding: 0;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 28px;
        }
      }

      & a {
        color: var(--marketplaceTextColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 414.286% */
        text-decoration-line: underline;
        text-transform: uppercase;
        background-color: #fff;
        padding: 0;
        box-shadow: none;
        margin-top: 12px;

        @media (max-width: 576px) {
          margin-top: 4px;
        }
      }
    }
  }
}

:global(#became-seller) {
  & > div {
    & > div {
      padding-top: 0;
    }
  }
  & header {
    display: block;
    & h2 {
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px;
      /* 125% */
      letter-spacing: 1px;
      max-width: 100%;
      max-width: 528px;
      margin: 0 auto;
      @media (max-width: 576px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    & p {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      display: block;
      margin: 32px auto;
    }
    & a {
      background-color: #7d8f9b;
      margin: 0 auto;
      display: block;
      width: max-content;
      padding: 8px 30px;
    }
  }
}

:global(#how-it-works) {
  & header {
    display: block;

    & h2 {
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px;
      /* 125% */
      letter-spacing: 1px;
      max-width: 100%;

      @media (max-width: 576px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  & > div {
    & > div {
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > div:not(:first-child) {
        width: calc(33% - 30px);
        max-width: 309px;

        @media (max-width: 767px) {
          width: 100%;
          max-width: 100%;
        }

        & h3 {
          color: var(--BLACK, #30332e);
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          /* 300% */
          margin: 0 0 16px;
          padding: 0;

          @media (max-width: 576px) {
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 12px;
          }
        }

        & p {
          color: var(--Dark-Wood, #484133);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          /* 138.889% */
          margin: 0;
          padding: 0;

          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

:global(#customer) {
  & > div {
    text-align: center;

    &:first-child {
      width: 50px;
      height: 50px;
      margin: auto;
    }
  }

  & h3 {
    color: #fcf8f2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
  }

  & a {
    color: #fcf8f2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    box-shadow: none;
    padding: 0;
    margin: 0;

    &:hover {
      background: none;
    }
  }
}
:global(#services-blog) {
  & > div {
    & > div {
      padding: 0px 0;
    }
  }
  & .text {
    margin-top: 12px;
  }
}
