.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

:global(#landing-hero) {
  @media (max-width: 767px) {
    justify-content: flex-end !important;
    background-color: #d5d5d5;
    .backgroundImageWrapper {
      &:before{
        content: '';
        left: 0;
        right: 0;
        bottom: 30px;
        top: 0;
        position: absolute;
        background-image: url('../../../../assets/heroMobile.png');
        background-position: 50% 19%;
        background-size: contain;
      }
      /* position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      & img {
        object-position: 100% 19%;
        min-height: 407px;
      } */
       & img{
        display: none;
   
       }
    }
    & > div:last-child {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  @media (max-width: 576px) {
  
    min-height: 407px;
    .backgroundImageWrapper {
      &:before{
        object-position: 84% 19%;
      }
      & img {
        object-position: 84% 19%;
        min-height: 407px;
      }
    }
  }
}
